<script setup>
import Authenticated from '@/pages/layouts/AuthenticatedLayout.vue'
import { Button, Accordion, Card, CardList } from '@/components/index'
import NewNoteModal from '../note/models/NewNoteModal.vue'
import NoteServices from '@/utils/services/noteServices'
import Note from './NoteSinglePage.vue'
import isBright from '@/utils/isBright'
import { store } from '@/store'
import { onMounted, ref } from 'vue'
import router from '../../router'

const user = store.user
const notes = ref()
const newNote = ref(false)
const isLoading = ref(true)
const activeNote = ref(null)
const selectedVue = ref('list-notes')
const service = new NoteServices()

const getOwner = (users) => {
  let name = null
  users.forEach(x => {
    if (x.pivot.owner === 1) {
      name = x.first_name
    }
  })
  return name
}

const viewNote = (noteId) => {
  let found = false
  notes.value.forEach(x => {
    if (x.id === noteId) {
      activeNote.value = x
      found = true
    }
  })

  if (found) {
    selectedVue.value = 'selected-note'
  }
}

const deleteNote = (note) => {
  service.deleteNote(note)
    .then(() => {
      router.go()
    })
}

onMounted(() => {
  service.getNotes(store.user.id)
    .then((response) => {
      isLoading.value = false
      notes.value = response.data.notes
    })
    .catch(() => {
      isLoading.value = false
    })
})
</script>

<template>
  <Authenticated v-if="selectedVue === 'list-notes'">
    <template #header>
      <span>Notes</span>
      <Button action="dark" @click="newNote = true">
        <i class="fas fa-plus"></i> Note
      </Button>
    </template>
    <Accordion open title="Your Notes">
      <div v-if="isLoading" class="isLoading" uk-spinner></div>
      <div class="grid-4" v-if="!store.user.preference || !store.user.preference.note || store.user.preference?.note?.listView === false">
        <Card v-for="note in notes" :key="note.id"
              :style="[isBright(note.colour) ? 'color: var(--tertiaryColour)' : '', 'background-color:' + note.colour]"
              :label="note.title + [note.user_id !== user.id ? ' by ' + getOwner(note.users) : '']"
              detail
              @click="viewNote(note.id)"
        />
      </div>
      <div v-else>
        <CardList v-for="note in notes" :key="note.id"
                  :label="note.title + [note.user_id !== user.id ? ' by ' + getOwner(note.users) : '']"
                  :style="[isBright(note.colour) ? 'color: var(--tertiaryColour)' : '', 'background-color:' + note.colour]"
                  @click="viewNote(note.id)"
        />
      </div>
    </Accordion>

  </Authenticated>

  <Note
      v-else
      :noteKey="activeNote.key"
      @changeVue="selectedVue = 'list-notes'"
      @deleteNote="deleteNote"
  />

  <NewNoteModal v-if="newNote" @closeModal="newNote = false" />
</template>

<style scoped>

</style>
